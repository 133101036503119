<template>
    <div>
        <Dialog @closeDialog="handleDialogClose($event)" />
        <h2 class="oleo">Verstuur {{ !$route.query.reactie ? 'nieuw bericht' : 'reactie op bericht' }}</h2>
        <p v-if="!$route.query.reactie">
            Heb je een vraag of suggestie? Terugkoppeling naar je matchmaker of een administratieve vraag? Via
            onderstaande formulier kun je een bericht verzenden.
        </p>

        <ErrorMessage />
        <form action="#" class="mijnps-form">
            <select
                v-if="!$route.query.reactie"
                name="typeBericht"
                id="typeBericht"
                size="1"
                v-model.number="typeBericht"
                :class="{ 'form-input-error': err.typeBericht }"
                @change="checkSendPhotos"
            >
                <option value="12">Bericht aan matchmaker ({{ naamBemiddelaar }})</option>
                <option value="99">Stuur een foto</option>
                <option value="7">Suggestie / Verbetertip</option>
                <option value="8">Klacht</option>
                <option value="6">Administratieve vraag</option>
                <option value="9">Succesverhaal</option>
                <option value="11">Bericht aan directie</option>
                <option value="10">Uitschrijving</option>
                <option value="12" v-if="this.$route.query.reactie" selected>Reactie op bericht</option>
            </select>

            <textarea
                name="inhoudBericht"
                id="inhoudBericht"
                style="width: 100%; height: 50vh"
                v-model="inhoudBericht"
                :class="{ 'form-input-error': err.inhoudBericht }"
            ></textarea>

            <button class="btn" type="submit" @click.prevent="handleSendMessage">Verstuur bericht</button>

            <div style="padding: 1.5rem; background-color: #cdcdcd" v-if="$route.query.reactie">
                <p style="margin-bottom: 1em">
                    Reactie op bericht van {{ vorigBericht ? vorigBericht.DatumTijd : '' }}:
                </p>
                <p style="white-space: pre-wrap">{{ vorigBericht ? vorigBericht.Bericht : '' }}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { errorToMessage } from '../functions/errorHandler';
    import ErrorMessage from '../components/ErrorMessage';

    export default {
        data() {
            return {
                typeBericht: null,
                inhoudBericht: null,
                berichtVerzonden: false,
                isReactie: false,
                vorigBericht: null,
                err: {
                    typeBericht: false,
                    inhoudBericht: false,
                },
            };
        },
        components: {
            ErrorMessage,
        },
        computed: {
            ...mapGetters(['naamBemiddelaar', 'haalBerichtNummer']),
        },
        mounted() {
            scrollTo(0, 0);
            const bericht = JSON.parse(sessionStorage.getItem('mijnps-tempBericht'));
            const reactieOpBerichtNummer = this.$route.query.reactie || null;

            if (reactieOpBerichtNummer !== null) {
                this.isReactie = true;
                this.vorigBericht = this.haalBerichtNummer(reactieOpBerichtNummer);
                this.typeBericht = 12; // Voor overzicht zou het fijn zijn een apart RE: optie te hebben of type bericht te kunnen herhalen

                return;
            } else if (bericht) {
                this.$dialog.confirm({
                    message:
                        'Je hebt eerder een bericht gemaakt dat nog niet verzonden is. Wil je met dit bericht verder gaan?',
                    confirmLabel: 'Ja',
                    cancelLabel: 'Nee',
                });
            }
        },
        beforeDestroy() {
            if (this.inhoudBericht && !this.berichtVerzonden && !this.isReactie) {
                let tempBericht = {
                    inhoudBericht: this.inhoudBericht,
                    typeBericht: this.typeBericht,
                };
                sessionStorage.setItem('mijnps-tempBericht', JSON.stringify(tempBericht));
            }
        },
        methods: {
            ...mapActions(['addNotification', 'stuurBericht']),
            checkSendPhotos(evt) {
                if (evt.target.value === '99') {
                    this.$router.push('/mijn-beschrijving/foto');
                }
            },
            handleDialogClose(evt) {
                if (evt === true && sessionStorage.getItem('mijnps-tempBericht')) {
                    const tempBericht = JSON.parse(sessionStorage.getItem('mijnps-tempBericht'));
                    this.inhoudBericht = tempBericht.inhoudBericht;
                    this.typeBericht = tempBericht.typeBericht;
                } else if (evt === false) {
                    sessionStorage.removeItem('mijnps-tempBericht');
                }
            },
            async handleSendMessage() {
                this.err.typeBericht = false;
                this.err.inhoudBericht = false;
                if (!this.typeBericht) {
                    this.err.typeBericht = true;
                    this.addNotification('Kies een type bericht');
                }
                if (!this.inhoudBericht || this.inhoudBericht.length === 0) {
                    this.err.inhoudBericht = true;
                    this.addNotification('Je kunt geen lege berichten versturen');
                }
                if (Object.values(this.err).includes(true)) {
                    return;
                } else {
                    this.stuurBericht({
                        typeBericht: this.typeBericht,
                        inhoudBericht: this.inhoudBericht,
                    })
                        .then(() => {
                            // On success
                            this.addNotification('Bericht verzonden');
                            this.berichtVerzonden = true;
                            sessionStorage.removeItem('mijnps-tempBericht');
                            this.$store.dispatch('getUserData');
                            this.$router.push('/bedankt?type=bericht');
                        })
                        .catch((err) => {
                            // On fail
                            const errorMessage = errorToMessage(err);
                            // this.$dialog.popup(errorMessage);
                            this.$store.commit('SET_ERROR', errorMessage);
                        });
                }
            },
        },
    };
</script>
